import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "../css/AdminNavbar.css";
import DriverBack from "./DriverBack";
import { CompanyContext } from "./CompanyContext";


const DriverNavbar = (props) => {
  const navigate = useNavigate();
  const { company, setCompany } = useContext(CompanyContext)

  const logout = () => {
    const token = Cookies.get("token");
    if (!token) navigate("/login");

    Cookies.remove("token");
    Cookies.remove("company");
    setCompany("")
    navigate("/login");
  };

  return (
    <div className="admin-navbar flex flexRow">
      <div className="flex flexRow">
        {props.route == "admin" || props.route == "driver" ? null : <DriverBack />}
        <p>{props.page}</p>
      </div>
      {
        company === "LockupFixing" ? <img
          className="a-nav-logo"
          src={process.env.PUBLIC_URL + "/fixing-3.png"}
          alt=""
        /> : <img
          className="a-nav-logo"
          src={process.env.PUBLIC_URL + "/logo3.png"}
          alt=""
        />
      }
      <button className="userBox" onClick={logout}>
        Logout
      </button>
    </div>
  );
};

export default DriverNavbar;
