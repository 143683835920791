import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/Driver.css";
import DriverNavbar from "./DriverNavbar";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const DriverPanel = () => {
  const { showLoading, hideLoading } = useLoading();
  const [username, setUsername] = useState("");
  const [driverId, setDriverId] = useState("");
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            // If the token is valid, fetch the admin's username
            setDriverId(response.data.driverId);

            axios
              .post(
                `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/driver/info`,
                { driverId: response.data.driverId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                setUsername(res.data.username);
              })
              .catch((err) => {
                console.error("Error fetching username", err);
              })
              .finally(hideLoading);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        });
    }
  }, [navigate]);

  const buttonNavigation = (e) => {
    e.preventDefault();

    navigate(`/driver/${e.target.id}`);
  };

  return (
    <div className="flex flexColumn admin-container">
      <DriverNavbar page="DRIVER DASHBOARD" route="driver" />
      <div className="admin-inner flex flexColumn">
        <h1 className="admin-title">
          Welcome <span>{username}</span>!
        </h1>
        <p className="admin-small">What are we shipping today?</p>
        <div className="driver-buttons flex flexRow">
          <button
            onClick={buttonNavigation}
            id="assignments"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/assignment.png"}
              alt=""
            />
            <p className="admin-button-name">Assignments</p>
          </button>
          <button
            onClick={buttonNavigation}
            id="completed"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/completed.png"}
              alt=""
            />
            <p className="admin-button-name">Completed</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DriverPanel;
