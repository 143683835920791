import React from "react";
import { useLoading } from "./LoadingContext";
import "../css/Loading.css";

const Loading = () => {
  const { isLoading } = useLoading();

  return (
    <div className={`loading-screen ${isLoading ? "" : "hidden"}`}>
      {/* <div className="spinner"></div> */}
      <img
        className="loading-truck"
        src={process.env.PUBLIC_URL + "/logo-load.png"}
        alt=""
      />
      <img
        className="loading-arrow"
        src={process.env.PUBLIC_URL + "/logo-arrow.png"}
        alt=""
      />
    </div>
  );
};

export default Loading;
