import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/RemoveDriver.css";
import AdminNavbar from "./AdminNavbar";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const RemoveDriver = () => {
  const { showLoading, hideLoading } = useLoading();
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            // Fetch the list of drivers
            axios
              .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/drivers`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                setDrivers(res.data);
              })
              .catch((err) => {
                console.error("Error fetching drivers", err);
              })
              .finally(hideLoading);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        });
    }
  }, [navigate]);

  const handleSelectChange = (e) => {
    const driverId = e.target.value;
    const driver = drivers.find((driver) => driver._id === driverId);
    setSelectedDriver(driver);
  };

  const handleRemove = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .delete(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/removedriver/${selectedDriver._id}`,
          //   { id: selectedDriver._id },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          alert(response.data);
          setDrivers(
            drivers.filter((driver) => driver._id !== selectedDriver._id)
          );
          setSelectedDriver(null);
        })
        .catch((err) => alert(err.response.data));
    }
  };

  return (
    <div className="flex flexColumn admin-container">
      <AdminNavbar page="REMOVE DRIVERS" route="removedriver" />
      <div className="admin-inner-remove-drivers flex flexColumn">
        <div className="remove-driver-container flex flexColumn">
          <h2 className="driver-title">REMOVE DRIVER</h2>
          <select onChange={handleSelectChange} defaultValue="">
            <option value="" disabled>
              Select a driver
            </option>
            {drivers.map((driver) => (
              <option key={driver._id} value={driver._id}>
                {driver.username}
              </option>
            ))}
          </select>
          {selectedDriver && (
            <div className="driver-details">
              <div className="driver-details-inner flex flexRow">
                <p className="remove-driver-username">
                  {selectedDriver.username}
                </p>
                <p className="remove-driver-phone">{selectedDriver.phone}</p>
              </div>
              <p className="driver-warning-text">
                This process cannot be undone!
              </p>
              <button className="remove-driver-button" onClick={handleRemove}>
                REMOVE DRIVER
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RemoveDriver;
