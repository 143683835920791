import React, { createContext, useState, useContext, useEffect } from "react";

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true); // Start with loading state true

  useEffect(() => {
    // Hide loading screen after a short delay on initial load
    const timer = setTimeout(() => setIsLoading(false), 400);
    return () => clearTimeout(timer);
  }, []);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
