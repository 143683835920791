import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/AddDriver.css";
import AdminNavbar from "./AdminNavbar";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const AddDriver = () => {
  const { showLoading, hideLoading } = useLoading();
  const [username, setUsername] = useState("");
  const [adminId, setAdminId] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    phone: "",
  });
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            // If the token is valid, fetch the admin's username
            setAdminId(response.data.adminId);

            axios
              .post(
                `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/info`,
                { adminId: response.data.adminId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                setUsername(res.data.username);
              })
              .catch((err) => {
                console.error("Error fetching username", err);
              })
              .finally(hideLoading);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        });
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/adddriver`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          alert(response.data);

          // RESET FORM DATA
          setFormData({
            username: "",
            password: "",
            phone: "",
          });
        })
        .catch((err) => alert(err.response.data));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Only allow numbers and limit to 10 digits
      const phonePattern = /^[0-9]{0,10}$/;
      if (!phonePattern.test(value)) {
        return;
      }

      // Validate Australian phone numbers
      const landlinePattern = /^(?:02|03|07|08)[0-9]{8}$/;
      const mobilePattern = /^04[0-9]{8}$/;
      const isValid = landlinePattern.test(value) || mobilePattern.test(value);

      if (!isValid && value.length === 10) {
        alert("Invalid Australian phone number");
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="flex flexColumn admin-container">
      <AdminNavbar page="ADD DRIVERS" route="adddriver" />
      <div className="admin-inner-add-drivers flex flexColumn">
        <form
          className="adddriver-form flex flexColumn"
          onSubmit={handleSubmit}
        >
          <h2 className="driver-title">ADD NEW DRIVER</h2>
          <p className="driver-form-label">Driver Name</p>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Driver Name"
            required
          />
          <p className="driver-form-label">Driver Password</p>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Driver Password"
            required
          />
          <p className="driver-form-label">Driver Phone Number</p>
          <div className="phone-input-box flex flexRow">
            <div className="phone-holder flex flexColumn">
              <p>+61</p>
            </div>
            <input
              className="phone-input"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Driver Phone Number"
              required
            />
          </div>

          <button className="adddriver-button" type="submit">
            ADD DRIVER
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDriver;
