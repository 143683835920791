import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AdminNavbar from "./AdminNavbar";
import PdfUploader from "./PdfUploader";
import { formatDistanceToNow } from "date-fns";
import "../css/TrackDelivery.css";


import mammoth from "mammoth";
import * as XLSX from "xlsx";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const TrackDelivery = () => {
  const { showLoading, hideLoading } = useLoading();
  const [showWarning, setShowWarning] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [clearPdfs, setClearPdfs] = useState(false);
  const { uniqueId } = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    driverId: "",
    driverName: "",
  });

  const { company } = useContext(CompanyContext);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/track/${uniqueId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setAssignment(response.data);

          axios
            .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/drivers`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              setDrivers(res.data);
            })
            .catch((err) => {
              console.error("Error fetching drivers", err);
            });


        })
        .catch((error) => {
          console.error("Error fetching assignment:", error);
          navigate("/admin/assignments");
        })
        .finally(hideLoading);
    }
  }, [uniqueId, navigate]);

  const viewFile = (e, filename) => {
    e.preventDefault();

    let buttons = document.querySelectorAll(".pdf-item")
    let loadingText = document.querySelector(".pdf-item-loading")

    loadingText.style.display = "block"

    buttons.forEach(async b => {
      b.classList.add("disabled-loading-button")
    })

    const token = Cookies.get("token");
    const fileExtension = filename.split(".").pop().toLowerCase();

    axios
      .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/pdf/${filename}`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const file = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(file);

        const container = document.querySelector(".pdf-list"); // Get the existing container

        // Clear previous previews
        const existingPreview = container.querySelector(".file-preview");
        if (existingPreview) {
          existingPreview.remove();
        }

        if (fileExtension === "pdf") {
          // Display PDF in iframe within the container
          let iframe = document.createElement("iframe");
          iframe.className = "file-preview"; // Add class to identify the preview element
          iframe.style.width = "100%";
          iframe.style.height = "50vh";
          iframe.src = url;
          container.appendChild(iframe);

          iframe.onload = () => {
            window.URL.revokeObjectURL(url);
          };
        } else if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
          // Display image within the container
          const img = document.createElement("img");
          img.className = "file-preview"; // Add class to identify the preview element
          img.src = url;
          img.style.width = "100%";
          img.style.maxWidth = "37rem";
          img.style.height = "auto";
          img.alt = "Image Preview";
          container.appendChild(img);
        } else if (fileExtension === "docx") {
          // Use mammoth.js to render docx file
          mammoth.convertToHtml({ arrayBuffer: file }).then((result) => {
            const docxContainer = document.createElement("div");
            docxContainer.className = "file-preview";
            docxContainer.innerHTML = result.value; // The converted HTML
            container.appendChild(docxContainer);
          });
        } else if (["xls", "xlsx"].includes(fileExtension)) {
          // Use SheetJS to render Excel file
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const htmlString = XLSX.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]]);

            const excelContainer = document.createElement("div");
            excelContainer.className = "file-preview";
            excelContainer.innerHTML = htmlString;
            container.appendChild(excelContainer);
          };
          reader.readAsArrayBuffer(file);
        } else {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          link.textContent = `Download ${filename.split("-")[2]}`;
          container.appendChild(link);
        }

        loadingText.style.display = "none"
        buttons.forEach(async b => {
          b.classList.remove("disabled-loading-button")
        })
      })
      .catch((error) => {
        buttons.forEach(async b => {
          b.classList.remove("disabled-loading-button")
        })
        loadingText.style.display = "none"
        console.error("Error fetching file:", error);
      });
  };


  const handlePdfsChange = (pdfFiles) => {
    setPdfs(pdfFiles);
  };


  const handleDelete = () => {
    console.log("handle delete")
    setButtonLoading(true)

    const token = Cookies.get("token");

    axios
      .post(
        `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/delete`,
        { uniqueId: uniqueId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        alert("Delivery deleted succesfully!");
        setButtonLoading(false);
        window.location.reload(); // Refresh the page

      })
      .catch((error) => {
        setButtonLoading(false);
        console.error("Error deleting delivery:", error);
      });
  };



  const handleWarning = () => {
    setShowWarning(!showWarning)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "driverId") {
      const selectedDriver = drivers.find((driver) => driver._id === value);
      if (selectedDriver) {
        setFormData((prevState) => ({
          ...prevState,
          driverId: value,
          driverName: selectedDriver.username,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        packageDetails: {
          ...prevState.packageDetails,
          [name]: value,
        },
      }));
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (buttonLoading) return;
    setButtonLoading(true);

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/reassign`,
          { uniqueId: uniqueId, driverId: formData.driverId, driverName: formData.driverName },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setButtonLoading(false);
          setTimeout(() => {
            window.location.reload(); // Refresh the page
          }, 1000);
        })
        .catch((error) => {
          setButtonLoading(false);
          console.error("Error reassinging the delivery:", error);
        });
    }
  };


  const handleUpload = () => {
    if (buttonLoading) return;
    setButtonLoading(true);

    const token = Cookies.get("token");
    const data = new FormData();
    pdfs.forEach((pdf) => {
      data.append("files", pdf);
    });

    axios
      .post(
        `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/upload-pdfs/${uniqueId}`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        alert("PDFs uploaded successfully");
        setButtonLoading(false);
        setClearPdfs(true);
        setTimeout(() => {
          setClearPdfs(false); // Reset the clearPdfs flag
          window.location.reload(); // Refresh the page
        }, 1000);
      })
      .catch((error) => {
        setButtonLoading(false);
        console.error("Error uploading PDFs:", error);
      });
  };

  if (!assignment) {
    return <div>Loading...</div>;
  }

  const formattedDate = (date) => {
    return `${new Date(date).toLocaleString("en-US", {
      timeZone: "Australia/Melbourne",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  };

  return (
    <div className="flex flexColumn assignment-delivery-container">
      <AdminNavbar page="DELIVERY TRACKING" route="assignments" />
      <div className="assignment-inner flex flexColumn">
        <h2
          className={`assignment-title ${assignment.status.toLowerCase().split(" ").join("-")}`}
        >
          {assignment.status.toUpperCase()}
        </h2>
        <div className="assignment-box-track flex flexColumn">
          <p className="assignment-box-label assignment-code">
            {assignment.packageDetails.name}
          </p>
          <div className="customer-container flex flexColumn">
            <p className="assignment-box-label-title">Customer</p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.ownerName}
            </p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.address}
            </p>
            <p className="assignment-box-label assignment-driver">
              {assignment.packageDetails.suburb}
            </p>
          </div>

          <div className="customer-container flex flexColumn">
            <p className="assignment-box-label-title">Driver</p>
            <p className="assignment-box-label assignment-driver">
              {assignment.driverName}
            </p>
          </div>

          <p className="assignment-box-label assignment-date">
            <span className="date-span">Assigned At:</span>{" "}
            {formattedDate(assignment.assignedAt)}{" "}
            <span className="date-greyed">
              {"("}
              {formatDistanceToNow(new Date(assignment.assignedAt), {
                addSuffix: true,
              })}
              {")"}
            </span>
            <br />
            <span className="date-span">Delivered At:</span>{" "}
            {formattedDate(assignment.updatedAt)}{" "}
            <span className="date-greyed">
              {"("}
              {formatDistanceToNow(new Date(assignment.updatedAt), {
                addSuffix: true,
              })}
              {")"}
            </span>
          </p>

          <div className="customer-container photo-list flex flexColumn">
            <p className="assignment-box-label-title">Photos</p>
            {assignment.photos.map((photo, index) => (
              <img
                key={index}
                src={`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/photos/${photo}`}
                alt={`Photo ${index}`}
                className="photo-item"
              />
            ))}
          </div>

          <div className="customer-container pdf-list flex flexColumn">
            <p className="assignment-box-label-title">Files</p>
            {assignment.pdfs.map((file, index) => (
              <button
                key={index}
                onClick={(e) => viewFile(e, file)}
                className="pdf-item"
              >
                {file.split("-")[2]}
              </button>

            ))}
            <p className="pdf-item-loading">LOADING...</p>
            <iframe id="pdf-container"></iframe>
          </div>

          <PdfUploader onPdfsChange={handlePdfsChange} clearPdfs={clearPdfs} />
          <div className="flex flexRow pdf-buttons">
            <label htmlFor="pdf-upload" className="custom-file-upload">
              Add Files
            </label>
            <button onClick={handleUpload} className="upload-button" disabled={buttonLoading}>
              {buttonLoading ? "Loading.." : "Upload PDFs"}
            </button>
          </div>
        </div>

        {assignment?.status?.toLowerCase() == "returned" ?
          <div className="reassign-container flex flexColumn">
            <form className="assign-form reassign-form flex flexColumn" onSubmit={handleSubmit}>
              <label htmlFor="driverId">Assign this package again</label>
              <select
                className="assign-select"
                id="driverId"
                name="driverId"
                value={formData.driverId}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Pick your driver
                </option>
                {drivers.map((driver) => (
                  <option key={driver._id} value={driver._id}>
                    {driver.username}
                  </option>
                ))}
              </select>
              <button className="assign-button" type="submit" disabled={buttonLoading}>
                {buttonLoading ? "Loading.." : "Assign Package"}
              </button>
            </form>
          </div>
          : null}


        <button onClick={handleWarning} className="delete-delivery-button" disabled={buttonLoading}>
          {buttonLoading ? "Loading.." : "Delete This Delivery"}
        </button>
        {showWarning ?
          <div className="delete-warning-container flex flexRow">
            <div className="dw-inner flex flexColumn">
              <p className="dw-inner-title">DELETE DELIVERY?</p>
              <div className="dw-details flex flexColumn">
                <p className="dw-job">Job: <span>{assignment.packageDetails.name}</span></p>
                <p>{assignment.packageDetails.description}</p>
                <div className="dw-details-customer flex flexColumn">
                  <p className="dw-customer">Customer</p>
                  <p>{assignment.packageDetails.ownerName}<br />{assignment.packageDetails.ownerPhone}<br />{assignment.packageDetails.address + " - " + assignment.packageDetails.suburb}</p>
                </div>
              </div>
              <p className="delete-warning">Are you sure you want to delete this file?<br /><span className="warning-text">This action is final and cannot be reversed.</span></p>
              <div className="dw-buttons flex flexColumn">
                <button className="dw-delete" onClick={handleDelete} disabled={buttonLoading}>DELETE</button>
                <button className="dw-cancel" onClick={handleWarning} disabled={buttonLoading}>Cancel</button>
              </div>
            </div>
          </div>
          : null}
      </div>
    </div>
  );
};

export default TrackDelivery;
