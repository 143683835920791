import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/SearchBar.css";
import { CompanyContext } from "./CompanyContext";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) return navigate("/login");

    if (query.trim() === "") {
      setResults([]);
      setShowDropdown(false);
      return;
    }

    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/search`,
          { params: { query }, headers: { Authorization: `Bearer ${token}` } }
        );
        setResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchResults();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // const handleResultClick = (result) => {
  //   console.log(result);
  //   setQuery(result._id);
  //   setShowDropdown(false);
  // };

  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight-search">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const statusesCss = {
    "Waiting For Driver": "status-waiting",
    "Being Delivered": "status-being",
    Delivered: "status-delivered",
  };

  const formattedDate = (date) => {
    return `${new Date(date).toLocaleString("en-US", {
      timeZone: "Australia/Melbourne",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  };

  return (
    <div className="searchbox-container">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search packages..."
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
        className="search-input"
      />

      <div className="search-results">
        {results.map((pkg) => (
          <a
            key={pkg._id}
            className="dropdown-item flex flexRow"
            href={`track/${pkg._id}`}
          >
            <div className="dropdown-item-left flex flexColumn">
              <p className="dropdown-code dropdown-label">
                <span className="dropdown-hash">#</span>{" "}
                {highlightText(pkg.packageDetails.name, query)}
              </p>
              <div className="flex flexColumn">
                <p className={`dropdown-status ${statusesCss[pkg.status]}`}>
                  <div
                    className={`dot ${formatStatusClass(pkg.status) + "-dot"}`}
                  ></div>{" "}
                  {pkg.status}
                </p>
                <p className="dropdown-update dropdown-label">
                  {formattedDate(pkg.updatedAt)}{" "}
                  <span className="update-status">
                    {"("}
                    {pkg.status == "Delivered" ? "Delivered" : "Last Updated"}
                    {")"}
                  </span>
                </p>
              </div>
            </div>
            <div className="dropdown-item-right flex flexColumn">
              <p className="dropdown-customer dropdown-label">
                {highlightText(pkg.packageDetails.ownerName, query)}
              </p>
              <p className="dropdown-address dropdown-label">
                {highlightText(pkg.packageDetails.address, query)}
              </p>
              <p className="dropdown-suburb dropdown-label">
                {highlightText(pkg.packageDetails.suburb, query)}
              </p>
              <div className="dropdown-driver-box flex flexRow">
                <img src={process.env.PUBLIC_URL + "/truckicon.png"} alt="" />
                <p className="dropdown-driver">{pkg.driverName}</p>
              </div>
            </div>
          </a>
        ))}
        {results?.length <= 0 ? <div>Search For Deliveries</div> : null}
      </div>
    </div>
  );
};

const formatStatusClass = (status) => {
  return status.toLowerCase().replace(/\s+/g, "-");
};

export default SearchBar;
