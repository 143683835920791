// CompanyContext.js
import React, { createContext, useState, useEffect } from 'react';
import Cookies from "js-cookie";

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
    const [company, setCompany] = useState(() => {
        // Load initial state from localStorage if available
        return Cookies.get('company') || null;

    });

    useEffect(() => {
        // Save company to localStorage whenever it changes
        if (company) {
            localStorage.setItem('company', company);
            Cookies.set("company", company)
        }
    }, [company]);

    return (
        <CompanyContext.Provider value={{ company, setCompany }}>
            {children}
        </CompanyContext.Provider>
    );
};
