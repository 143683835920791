import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import "../css/Admin.css";
import AdminNavbar from "./AdminNavbar";

import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const Admin = () => {
  const { showLoading, hideLoading } = useLoading();
  const [username, setUsername] = useState("");
  const [adminId, setAdminId] = useState("");
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext)

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            // If the token is valid, fetch the admin's username
            setAdminId(response.data.adminId);

            axios
              .post(
                `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/info`,
                { adminId: response.data.adminId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                setUsername(res.data.username);
              })
              .catch((err) => {
                console.error("Error fetching username", err);
              })
              .finally(hideLoading);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        });
    }
  }, [navigate]);

  const buttonNavigation = (e) => {
    e.preventDefault();

    navigate(`/admin/${e.target.id}`);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="flex flexColumn admin-container">
      <AdminNavbar page="ADMIN DASHBOARD" route="admin" />
      <div className="admin-inner flex flexColumn">
        <h1 className="admin-title">
          Welcome <span>{capitalizeFirstLetter(username)}</span>!
        </h1>
        <p className="admin-small">What are we shipping today?</p>
        <div className="admin-buttons flex flexRow">
          <button
            onClick={buttonNavigation}
            id="assign"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/new.png"}
              alt=""
            />
            <p className="admin-button-name">New Assignment</p>
          </button>
          <button
            onClick={buttonNavigation}
            id="track"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/box.png"}
              alt=""
            />
            <p className="admin-button-name">Track Deliveries</p>
          </button>
          <button
            onClick={buttonNavigation}
            id="adddriver"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/add.png"}
              alt=""
            />
            <p className="admin-button-name">Add Drivers</p>
          </button>
          <button
            onClick={buttonNavigation}
            id="removedriver"
            className="flex flexColumn admin-button"
          >
            <img
              className="admin-button-img"
              src={process.env.PUBLIC_URL + "/remove.png"}
              alt=""
            />
            <p className="admin-button-name">Remove Drivers</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
