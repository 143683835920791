import React from "react";
import { useLoading } from "./LoadingContext";

import lines from "../assets/lines4.png"


const Lines = () => {


    return (
        <div className="lines">
            <img className="lines-img" src={lines} />
        </div>
    );
};

export default Lines;
