import React, { useState, useEffect } from "react";

const PdfUploader = ({ onPdfsChange, clearPdfs }) => {
  const [pdfs, setPdfs] = useState([]);
  const [pdfPreviews, setPdfPreviews] = useState([]);

  useEffect(() => {
    if (clearPdfs) {
      setPdfs([]);
      setPdfPreviews([]);
    }
  }, [clearPdfs]);

  const handlePdfChange = (e) => {
    const files = Array.from(e.target.files);

    setPdfs((prevPdfs) => [...prevPdfs, ...files]);

    const previews = files.map((file) => URL.createObjectURL(file));
    setPdfPreviews((prevPreviews) => [...prevPreviews, ...previews]);

    onPdfsChange([...pdfs, ...files]);
  };

  const handleRemovePdf = (index) => {
    const updatedPdfs = pdfs.filter((_, i) => i !== index);
    const updatedPreviews = pdfPreviews.filter((_, i) => i !== index);

    setPdfs(updatedPdfs);
    setPdfPreviews(updatedPreviews);

    onPdfsChange(updatedPdfs);
  };

  return (
    <div className="pdf-uploader">
      <input
        id="pdf-upload"
        className="pdf-input"
        type="file"
        onChange={handlePdfChange}
        multiple
        accept=".png, .jpeg, .jpg, .docx, .pdf, .xlsx, .xls"
      />
      <div className="pdf-previews flex flexColumn">
        {pdfPreviews.map((preview, index) => (
          <div key={index} className="pdf-preview-item">
            <iframe src={preview} title={`PDF Preview ${index + 1}`} />
            <button onClick={() => handleRemovePdf(index)}>Remove</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PdfUploader;
