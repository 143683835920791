import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import PdfUploader from "./PdfUploader";
import "../css/AssignPage.css";
import { useLoading } from "./LoadingContext";
import { CompanyContext } from "./CompanyContext";

const AdminForm = () => {
  const { showLoading, hideLoading } = useLoading();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [formData, setFormData] = useState({
    driverId: "",
    driverName: "",
    packageDetails: {
      name: "",
      description: "",
      image: "",
      ownerName: "",
      address: "",
      suburb: "",
      ownerPhone: "",
    },
    pdfs: [],
  });
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [clearPdfs, setClearPdfs] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [showAddButton, setShowAddButton] = useState(false);

  const { company } = useContext(CompanyContext)
  const navigate = useNavigate();


  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      showLoading();
      axios
        .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (!response.data.valid) {
            Cookies.remove("token");
            navigate("/login");
          } else {
            axios
              .get(`${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/drivers`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                setDrivers(res.data);
              })
              .catch((err) => {
                console.error("Error fetching drivers", err);
              });

            axios
              .get(
                `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/customers`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                setCustomers(res.data);
              })
              .catch((err) => {
                console.error("Error fetching customers", err);
              })
              .finally(hideLoading);
          }
        })
        .catch(() => {
          Cookies.remove("token");
          navigate("/login");
        });
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const filtered = customers.filter((customer) =>
      customer.name.toLowerCase().includes(value.toLowerCase())
    );

    if (name === "ownerPhone") {
      const phonePattern = /^[0-9]{0,10}$/;
      if (!phonePattern.test(value)) {
        return;
      }

      const landlinePattern = /^(?:02|03|07|08)[0-9]{8}$/;
      const mobilePattern = /^04[0-9]{8}$/;
      const isValid = landlinePattern.test(value) || mobilePattern.test(value);

      if (!isValid && value.length === 10) {
        alert("Invalid Australian phone number");
      }

      setFormData((prevState) => ({
        ...prevState,
        packageDetails: {
          ...prevState.packageDetails,
          ownerPhone: value,
        },
      }));
    } else if (name === "ownerName") {
      setInputValue(value);


      setFilteredCustomers(filtered);
      setDropdownVisible(value.length > 0);

      setFormData((prevState) => ({
        ...prevState,
        packageDetails: {
          ...prevState.packageDetails,
          ownerName: value,
        },
      }));

    } else if (name === "driverId") {
      const selectedDriver = drivers.find((driver) => driver._id === value);
      if (selectedDriver) {
        setFormData((prevState) => ({
          ...prevState,
          driverId: value,
          driverName: selectedDriver.username,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        packageDetails: {
          ...prevState.packageDetails,
          [name]: value,
        },
      }));
    }

    // Show add button only if there are no matches and input is not empty
    setShowAddButton(filtered.length === 0 && value.trim() !== "" && formData.packageDetails.ownerPhone.trim() !== "");
  };

  const handleCustomerSelect = (customer) => {
    setFormData((prevState) => ({
      ...prevState,
      packageDetails: {
        ...prevState.packageDetails,
        ownerName: customer.name,
        ownerPhone: customer.phone.substring(3),
      },
    }));

    setFilteredCustomers([]);
    setInputValue(customer.name);
    setDropdownVisible(false);
    setShowAddButton(false);
  };

  const handleInputClick = () => {
    if (inputValue.length > 0) {
      setDropdownVisible(true);
    }
  };

  const handleAddCustomer = () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/add-customer`,
          {
            name: formData.packageDetails.ownerName,
            phone: `+61${formData.packageDetails.ownerPhone}`,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          alert(response.data.message);
          setCustomers([...customers, response.data.customer]);
          setDropdownVisible(false);
          setShowAddButton(false);
        })
        .catch((error) => {
          console.error("Error adding customer:", error);
          alert("Error adding customer.");
        });
    }
  };

  const handlePdfsChange = (pdfFiles) => {
    setFormData((prevState) => ({
      ...prevState,
      pdfs: pdfFiles,
    }));
  };

  const handleUpload = () => {
    const data = new FormData();
    pdfs.forEach((pdf) => {
      data.append("files", pdf);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (buttonLoading) return;
    setButtonLoading(true);

    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    } else {
      const data = new FormData();
      data.append("driverId", formData.driverId);
      data.append("driverName", formData.driverName);
      data.append("packageDetails", JSON.stringify(formData.packageDetails));
      formData.pdfs.forEach((pdf) => {
        data.append("files", pdf);
      });

      axios
        .post(
          `${process.env[`REACT_APP_R_BASE_SERVER_URL_${company.toUpperCase()}`]}/admin/assign-package`,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          alert(response.data);
          setFormData({
            driverId: "",
            driverName: "",
            packageDetails: {
              name: "",
              description: "",
              image: "",
              ownerName: "",
              address: "",
              suburb: "",
              ownerPhone: "",
            },
            pdfs: [],
          });
          setInputValue("");
          setClearPdfs(true);
          setTimeout(() => setClearPdfs(false), 1000);
          setButtonLoading(false);
        }).catch((e) => {
          setButtonLoading(false);
          return console.log(e)
        })

    }
  };

  return (
    <div className="flex flexColumn admin-container">
      <AdminNavbar page="NEW ASSIGNMENT" route="assign" />
      <div className="flex flexColumn assign-container">
        <form className="assign-form flex flexColumn" onSubmit={handleSubmit}>
          <h2 className="assign-title">NEW ASSIGNMENT</h2>
          <select
            className="assign-select"
            name="driverId"
            value={formData.driverId}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Pick your driver
            </option>
            {drivers.map((driver) => (
              <option key={driver._id} value={driver._id}>
                {driver.username}
              </option>
            ))}
          </select>
          <input
            className="assign-input assign-code"
            type="text"
            name="name"
            value={formData.packageDetails.name}
            onChange={handleChange}
            placeholder="Job Number"
            required
          />
          <div className="flex flexColumn assign-form-client">
            <p className="assign-form-title">Client</p>
            <input
              className="assign-input"
              type="text"
              name="ownerName"
              value={inputValue}
              onChange={handleChange}
              onClick={handleInputClick}
              placeholder="Client Name"
              required
            />
            {dropdownVisible && filteredCustomers.length > 0 && (
              <div className="customer-dropdown flex flexColumn" ref={dropdownRef}>
                {filteredCustomers.slice(0, 5).map((customer) => (
                  <p
                    key={customer._id}
                    className="customer-option"
                    onClick={() => handleCustomerSelect(customer)}
                  >
                    {customer.name}
                  </p>
                ))}
              </div>
            )}
            <div className="phone-input-box flex flexRow">
              <div className="phone-holder flex flexColumn">
                <p>+61</p>
              </div>
              <input
                className="assign-input phone-input"
                type="text"
                name="ownerPhone"
                value={formData.packageDetails.ownerPhone}
                onChange={handleChange}
                placeholder="Client Phone Number"
                required
              />
            </div>
            {showAddButton && (
              <button type="button" className="addcustomer-button" onClick={handleAddCustomer}>
                Add to Customers
              </button>
            )}
          </div>
          <div className="flex flexColumn assign-form-client">
            <p className="assign-form-title">Address</p>
            <input
              className="assign-input"
              type="text"
              name="address"
              value={formData.packageDetails.address}
              onChange={handleChange}
              placeholder="Address"
              required
            />
            <input
              className="assign-input"
              type="text"
              name="suburb"
              value={formData.packageDetails.suburb}
              onChange={handleChange}
              placeholder="Suburb"
              required
            />
          </div>
          <input
            className="assign-input"
            type="text"
            name="description"
            value={formData.packageDetails.description}
            onChange={handleChange}
            placeholder="Package Description"
            required
          />

          <PdfUploader onPdfsChange={handlePdfsChange} clearPdfs={clearPdfs} />
          <div className="flex flexRow pdf-buttons">
            <label htmlFor="pdf-upload" className="custom-file-upload assign-pdf-upload">
              Add Files
            </label>
          </div>

          <button className="assign-button" type="submit" disabled={buttonLoading}>
            {buttonLoading ? "Loading.." : "Assign Package"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminForm;
