import React from "react";

const ECGMEDIA = () => {
    return (
        <div style={{ position: "fixed", marginBottom: ".6rem", bottom: "0", left: "0" }}>
            <a href="https://ecgmedia.org" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#2c2c2c", padding: ".6rem", margin: "0" }}>
                powered by <span style={{ fontWeight: "700" }}>ECG</span>{" "}
                <span style={{ fontWeight: "500" }}>MEDIA</span>
            </a>
        </div>
    );
};

export default ECGMEDIA;
