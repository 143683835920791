import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminForm from "./components/AssignPage";
import AdminPanel from "./components/AdminPanel";
import AssignmentDelivery from "./components/AssignmentDelivery";
import Login from "./components/Login";

import "./App.css";
import "./css/fonts.css";
import "./css/sizes.css";
import AddDriver from "./components/AddDriver";
import RemoveDriver from "./components/RemoveDriver";
import TrackShipments from "./components/TrackShipments";
import DriverPanel from "./components/DriverPanel";
import DriverAssignments from "./components/DriverAssignments";
import DriverCompleted from "./components/DriverCompleted";
import TrackDelivery from "./components/TrackDelivery";
import UserPage from "./components/UserPage";

import SupportButton from "./components/SupportButton";


import Loading from "./components/Loading";
import { LoadingProvider } from "./components/LoadingContext";
import SearchBar from "./components/SearchBar";
import ECGMEDIA from "./components/ECGMEDIA";

import { CompanyProvider } from "./components/CompanyContext";
import Lines from "./components/Lines";
// import HubSpotScriptManager from './components/HubSpotScriptManager';



const App = () => (
  <CompanyProvider>
    <LoadingProvider>
      <Router>
        <Loading />
        <ECGMEDIA />
        <Lines />
        {/* <HubSpotScriptManager /> */}
        {/* <SupportButton /> */}
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* ADMIN ROUTES */}
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/search" element={<SearchBar />} />
          <Route path="/admin/assign" element={<AdminForm />} />
          <Route path="/admin/track" element={<TrackShipments />} />
          <Route path="/admin/track/:uniqueId" element={<TrackDelivery />} />
          <Route path="/admin/adddriver" element={<AddDriver />} />
          <Route path="/admin/removedriver" element={<RemoveDriver />} />

          {/* USER ROUTES */}
          <Route path="/delivery/:uniqueId" element={<UserPage />} />

          {/* DRIVER ROUTES */}
          <Route path="/driver" element={<DriverPanel />} />
          <Route path="/driver/assignments" element={<DriverAssignments />} />
          <Route path="/driver/completed" element={<DriverCompleted />} />
          <Route
            path="/driver/assignments/:uniqueId"
            element={<AssignmentDelivery />}
          />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </LoadingProvider>
  </CompanyProvider>
);

export default App;
